.accountUpgrade>tbody {
    font-size: x-large;
}

.price-container {
    position: relative;
    margin-bottom: 20px;
}

.price {
    padding-left: 8px;
    font-size: 2.8em;
    font-weight: 700;
}

.dollar {
    top: 5px;
    position: absolute;
    margin-right: 10px;
    font-weight: 400;
}

.accountUpgrade thead>tr>th {
    color: black;
}

.accountUpgrade th {
    font-weight: 400;
    font-size: 1.2em;
}

.accountUpgrade .featureName {
    font-weight: bold;
}

.accountUpgrade button {
    font-weight: 400;
    font-size: 2em;
}

.accountUpgrade button:hover {
    background-color: #5E92D3;
}